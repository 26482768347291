import React from "react";
import Date1 from "../date";
import { level2Access } from "../../../utils/roles";
import { getCurrentUser } from "../../../services/authService";

const PhaseList = (props) => {
  const user = getCurrentUser();
  const data = props.data;
  function dateShower(date) {
    if (date && !(date instanceof Date)) {
      console.log(date);
      let dateString = date.slice(0, 10);
      dateString = dateString.split("-");
      return dateString[1] + "-" + dateString[2] + "-" + dateString[0];
    }
  }
  console.log(data);
  return (
    <div className="fragmentList-Container">
      {data.length > 0 ? (
        <div className="fragmentList-List">
          {data.map((phase, i) => (
            <div className="fragmentList-Item" key={i.toString()}>
              <div className="fragmentList-Item-Title flex-start grow05">
                {`Phase ${phase.phase ?? 0}`}
              </div>
              <div className="fragmentList-Item-Title center">
                {phase.inDate ? dateShower(phase.inDate) : "-"}
              </div>
              {(data.length > 1 ? true : i !== 0) &&
              i !== data.length - 1 &&
              level2Access(user) ? (
                <>
                  <Date1
                    onChange={(json) => {
                      props.modifyPhase(json, i);
                    }}
                    value={phase.outDate}
                  />

                  <button
                    className="btn btn-danger"
                    onClick={() => props.deletePhase(phase.id)}
                  >
                    Delete
                  </button>
                </>
              ) : (
                <div className="fragmentList-Item-Title center">
                  {phase.outDate ? dateShower(phase.outDate) : "Current"}
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="fragmentList-NoData">
          No Previous Phases in this Admission
        </div>
      )}
    </div>
  );
};

export default PhaseList;
