export function formatAsCurrency(amount, currency = "USD", locale = "en-US") {
  // Convert the input to a number
  const number = parseFloat(amount);

  // Check if the input is a valid number
  if (isNaN(number)) {
    return "Invalid number";
  }

  // Use Intl.NumberFormat to format the number as currency
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(number);
}
