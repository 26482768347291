import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getAllCenters } from "../../services/centerService";

const TopBar = ({ user }) => {
  const [userCenter, setUserCenter] = useState("Dashboard");
  const location = useLocation();

  function checkSecondPath(url) {
    // Split the string by '/' and filter out empty strings
    const parts = url.split("/").filter((part) => part);
    // Check if the second part is 'resident'
    return parts.length > 1 && parts[1] === "resident";
  }

  useEffect(() => {
    const getandSetUser = async () => {
      try {
        //get Centers
        let centers = await getAllCenters();
        console.log(user, "p");
        centers.forEach((center) => {
          if (center.ID === user.Center) setUserCenter(center.Name);
        });
      } catch (error) {
        //
      }
    };
    if (user.Center) {
      getandSetUser();
    }
  }, [user]);
  console.log(window.location.pathname);
  return (
    <div className="topBar-Container">
      <div className="topBar-Sections">
        {window.location.pathname === "/dashboard" ||
        window.location.pathname === "/dashboard/" ? (
          <p className="font-semibold text-[20pt] pl-5">Resident Dashboard</p>
        ) : (
          <div className="topBar-Sections-1">
            <i
              className="fa fa-arrow-left fa-2x primary-text cursor-pointer"
              aria-hidden="true"
              onClick={(e) => {
                e.preventDefault();
                window.history.back();
              }}
            ></i>
          </div>
        )}
        <div className="primary-text">
          {location && checkSecondPath(location.pathname)
            ? "Resident Summary"
            : ""}
        </div>
        <div className="topBar-Sections-2">
          <div className="topBar-Sections-2-profileBox flex">
            <div className="min-w-[15vw]">
              <div className="text-end">{`${user.firstName} ${user.lastName}`}</div>
              <div className="text-end">
                {user.isAdmin
                  ? "Admin"
                  : user.isIntakeCoordinator
                  ? "Intake Coordinator"
                  : user.isCenterCoordinator
                  ? "Center Coordinator"
                  : user.isCaseCoordinator
                  ? "Case Coordinator"
                  : user.isFinance
                  ? "Finance"
                  : user.isHouseManager
                  ? "House Manager"
                  : "-"}
              </div>
              <div className="text-end text-teal-800">
                {`${
                  user.isAdmin
                    ? "Admin Dashboard"
                    : user.Center
                    ? userCenter
                    : "New Hope Center"
                }`}
              </div>
              {/* <div className="primary-text">{user.email}</div> */}
            </div>
          </div>
          <div className="topBar-Sections-2-Logout">
            <Link to="/dashboard/myProfile" className="nav-item">
              <div className="user-profile-box">
                <i
                  className="fa fa-user fa-2x light-text"
                  aria-hidden="true"
                ></i>
              </div>
            </Link>
            <Link to="/logout" className="nav-item">
              <div className="topBar-Sections-2-Logout">
                <i
                  className="fa fa-sign-out fa-2x primary-text paddingLeft1"
                  aria-hidden="true"
                ></i>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
    // {/* <h5 className="light-text">Intake Coordinator</h5> */}
  );
};

export default TopBar;
