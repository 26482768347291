import React from "react";

import { getCurrentUser } from "../../services/authService";
import { financeAccess } from "../../utils/roles";
import BillingManagement from "../BillingProfileManagement";
import GenerateBilling from "../../components/GenerateBilling";

function Billing() {
  const user = getCurrentUser();
  return (
    <div className="reports-Container">
      <div className="createResident-Container-headSection">
        <h2 className="text-teal-600 text-4xl">Billing Dashboard</h2>
      </div>
      <div className="reports-Container-Section">
        {financeAccess(user) && (
          <>
            <div className="flex flex-col space-y-5 mt-4">
              <div>
                <div>
                  <GenerateBilling />
                </div>
              </div>
              <div>
                <BillingManagement />
              </div>
              <div className="residentView-sectionBox mx-0">
                <div className="w-full flex flex-row justify-between mb-4">
                  <div>
                    <h2 className="text-3xl font-semibold text-teal-600 mb-6">
                      Previous Billing
                    </h2>
                  </div>
                  <div>
                    {/* {!showForm && (
            <button
              onClick={() => {
                setShowForm(true);
                setIsEditing(false);
                setNewInfraction({
                  ID: "",
                  Infraction: "",
                  Points: "",
                  Dismissal: "",
                });
              }}
              className="bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600 text-lg no-underline"
            >
              Create New Infraction
            </button>
          )} */}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Billing;
