import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaPen, FaTrash } from "react-icons/fa";
import {
  createServiceAgreement,
  deleteServiceAgreement,
  getAllServiceAgreement,
  updateServiceAgreement,
} from "../../services/serviceAgreementService";

function dateFormatter(d) {
  if (d) return d.slice(0, 10);
}

const ServiceAgreementLetters = ({ ResID, level4Access }) => {
  const [salList, setSalList] = useState([]);
  async function fetchData() {
    const sal = await getAllServiceAgreement(ResID);
    sal?.data?.map((sal) => {
      sal.StartDate = dateFormatter(sal.StartDate);
      sal.EndDate = dateFormatter(sal.EndDate);
    });
    setSalList(sal.data);
  }
  useEffect(() => {
    if (ResID) fetchData();
  }, [ResID]);

  // State to manage the visibility of the form and editing
  const [showForm, setShowForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);

  // Form state for adding/editing a SAL
  const [newSAL, setNewSAL] = useState({
    ID: "",
    SALNumber: "",
    ResID: "",
    StartDate: "",
    EndDate: "",
    Rate: "",
  });

  // Handle input change for the form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSAL({ ...newSAL, [name]: value });
  };

  // Add a new SAL to the list
  const addSAL = async () => {
    if (isEditing && editingIndex !== null) {
      const res = await updateServiceAgreement(newSAL);
      if (res.status === 200) {
        fetchData();
        toast.success("Service Agreement updated successfully");
      } else {
        toast.error("Failed to update Service Agreement");
      }
      setIsEditing(false);
      setEditingIndex(null);
    } else {
      const newServiceAgreement = {
        ...newSAL,
        ResID,
      };
      let res = await createServiceAgreement(newServiceAgreement);
      if (res.status === 201) {
        let ID = res?.data?.ID;
        setSalList([
          ...salList,
          {
            ID,
            SALNumber: newServiceAgreement.SALNumber,
            ResID,
            StartDate: newServiceAgreement.StartDate,
            EndDate: newServiceAgreement.EndDate,
            Rate: newServiceAgreement.Rate,
          },
        ]);
        toast.success("Service Agreement created successfully");
        // setSalList([newServiceAgreement, ...salList]);
      } else {
        toast.error("Failed to create Service Agreement");
      }
    }
    setNewSAL({
      ID: "",
      SALNumber: "",
      ResID: "",
      StartDate: "",
      EndDate: "",
      Rate: "",
    });
    setShowForm(false);
  };

  // Handle the "Edit" button click
  const editSAL = (id) => {
    const salToEdit = salList.find((sal) => sal.ID === id);
    console.log(salToEdit);
    setNewSAL(salToEdit);
    setIsEditing(true);
    setEditingIndex(salList.findIndex((sal) => sal.ID === id));
    setShowForm(true);
  };

  const deleteSAL = async (id) => {
    const res = await deleteServiceAgreement(id);
    if (res.status === 200) {
      toast.success("Service Agreement deleted successfully");
      const updatedSALs = salList.filter((sal) => sal.ID !== id);
      setSalList(updatedSALs);
    } else {
      toast.error("Failed to delete Service Agreement");
    }
  };

  // Cancel adding or editing a SAL
  const cancelAddSAL = () => {
    setNewSAL({
      ID: "",
      SALNumber: "",
      ResID: "",
      StartDate: "",
      EndDate: "",
      Rate: "",
    });
    setIsEditing(false);
    setShowForm(false);
  };

  function dateShower(date) {
    if (date) {
      let dateString = date.slice(0, 10);
      dateString = dateString.split("-");
      return dateString[1] + "-" + dateString[2] + "-" + dateString[0];
    }
  }

  const getStatus = (startDate, endDate) => {
    const today = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);
    const daysUntilEnd = (end - today) / (1000 * 60 * 60 * 24);

    if (start > today) {
      return "Upcoming";
    } else if (end < today) {
      return "Expired";
    } else if (daysUntilEnd <= 30 && daysUntilEnd > 0) {
      return "Expiring";
    } else {
      return "Current";
    }
  };

  const getRowClass = (startDate, endDate) => {
    const status = getStatus(startDate, endDate);
    return status === "Current" || status === "Expiring"
      ? "bg-teal-600 text-white border-t border-gray-200"
      : "border-t border-gray-200";
  };

  return (
    <div>
      <div className="residentView-sectionBox-header">
        <h4 className="primary">Service Agreement Letters</h4>
        {level4Access ? (
          showForm == false ? (
            <button className="b" onClick={() => setShowForm(true)}>
              Add New SAL
            </button>
          ) : (
            <button
              className="b blackButton"
              onClick={() => {
                cancelAddSAL();
                setShowForm(false);
              }}
            >
              Cancel
            </button>
          )
        ) : (
          <></>
        )}
      </div>
      <div className="p-1"></div>
      {!showForm && (
        <div className="max-h-[230px] overflow-auto pt-2">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  SAL
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  Rate
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  Start Date
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  End Date
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  Status
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody>
              {salList
                .sort((a, b) => new Date(b.EndDate) - new Date(a.EndDate))
                .map((sal) => (
                  <tr
                    key={sal.ID}
                    className={`${getRowClass(sal.StartDate, sal.EndDate)}`}
                  >
                    <td className="px-4 py-2 whitespace-nowrap">
                      {sal.SALNumber}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">{sal.Rate}</td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      {dateShower(sal.StartDate)}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      {dateShower(sal.EndDate)}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      {getStatus(sal.StartDate, sal.EndDate)}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      <button
                        onClick={() => editSAL(sal.ID)}
                        className="mr-4 text-gray-400"
                      >
                        <FaPen />
                      </button>
                      <button
                        onClick={() => deleteSAL(sal.ID)}
                        className="text-gray-700"
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      {showForm && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            addSAL();
          }}
        >
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-teal-700">SAL Number</label>
              <input
                type="text"
                name="SALNumber"
                placeholder="SAL Number"
                value={newSAL.SALNumber}
                onChange={handleInputChange}
                className="border p-2 rounded w-full"
                required
              />
            </div>
            <div>
              <label className="block text-teal-700">Rate</label>
              <input
                type="text"
                name="Rate"
                placeholder="Rate"
                value={newSAL.Rate}
                onChange={(e) => {
                  const value = e.target.value;
                  // Regular expression to allow numbers with up to two decimal places
                  if (/^\d*\.?\d{0,2}$/.test(value)) {
                    handleInputChange(e); // Only update if input matches regex
                  }
                }}
                className="border p-2 rounded w-full"
                required
              />
            </div>
            <div>
              <label className="block text-teal-700">Start Date</label>
              <input
                type="date"
                name="StartDate"
                value={newSAL.StartDate}
                onChange={handleInputChange}
                className="border p-2 rounded w-full"
                required
              />
            </div>
            <div>
              <label className="block text-teal-700">End Date</label>
              <input
                type="date"
                name="EndDate"
                value={newSAL.EndDate}
                onChange={handleInputChange}
                className="border p-2 rounded w-full"
                required
              />
            </div>
          </div>
          {/* Save Button */}
          <div className="text-center">
            <button
              type="submit"
              className="b py-2.5 px-4 w-auto rounded mt-6 "
            >
              {isEditing ? "Save" : "Add"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ServiceAgreementLetters;
