import Joi from "joi-browser";

export function getAdmissionobject() {
  return [
    [
      {
        type: "date",
        size: "grow1",
        name: "admission_0_0_GuestInDate",
        label: "Guest In Date",
        value: null,
        schema: Joi.date().required(),
      },
      {
        type: "date",
        size: "grow1",
        name: "admission_0_1_ProgramInDate",
        label: "Program In Date",
        value: null,
        schema: Joi.date().allow(null),
      },
      {
        type: "date",
        size: "grow1",
        name: "admission_0_2_EstMoveOutDate",
        label: "Est Move Out Date",
        value: null,
        schema: Joi.date().allow(null),
      }],[
        {
          type: "input",
          typeName: "text",
          size: "grow1",
          name: "admission_1_0_WhoReferred",
          label: "Who Referred",
          value: undefined,
          schema: Joi.string().max(50),
        },
        {
          type: "input",
          typeName: "text",
          size: "grow1",
          name: "admission_1_1_WhoReferredRelationship",
          label: "Who Referred - Relationship",
          value: undefined,
          schema: Joi.string().max(36),
        },
    ],
    [
      {
        type: "yesNo",
        size: "grow1",
        name: "admission_2_0_HasMentalHealthChallanges",
        label: "Has Mental Health Challanges?",
        value: undefined,
        schema: Joi.boolean(),
      },
      {
        type: "yesNo",
        size: "grow1",
        name: "admission_2_1_WasDomesticallyAbused",
        label: "Was Domestically Abused?",
        value: undefined,
        schema: Joi.boolean(),
      },
      {
        type: "yesNo",
        size: "grow1",
        name: "admission_2_2_WasHomeless",
        label: "Was Homeless?",
        value: undefined,
        schema: Joi.boolean(),
      },
      {
        type: "yesNo",
        size: "grow1",
        name: "admission_2_3_WasJobless",
        label: "Was Jobless?",
        value: undefined,
        schema: Joi.boolean(),
      },
    ],
    [
      {
        type: "yesNo",
        size: "grow1",
        name: "admission_3_0_IsRestricted",
        label: "Is Restricted?",
        value: true,
        schema: Joi.boolean(),
      },
      {
        type: "yesNo",
        size: "grow1",
        name: "admission_3_1_IsApprovedPartner",
        label: "Is Approved Partner?",
        value: false,
        schema: Joi.boolean(),
      },
      {
        type: "yesNo",
        size: "grow1",
        name: "admission_3_2_IsApprovedBabySitter",
        label: "Is Approved Baby Sitter?",
        value: undefined,
        schema: Joi.boolean(),
      },
      {
        type: "yesNo",
        size: "grow1",
        name: "admission_3_3_CanSelfSignout",
        label: "Can Self Signout?",
        value: false,
        schema: Joi.boolean(),
      },
    ],[
      {
        type: "input",
        typeName: "text",
        size: "grow1",
        name: "admission_4_0_TimesCompletedTreatment",
        label: "Times Completed Treatment",
        value: undefined,
        schema: Joi.string(),
      },
      {
        type: "input",
        typeName: "text",
        size: "grow1",
        name: "admission_4_1_PreviousInpatientAttempts",
        label: "Previous Inpatient Attempts",
        value: undefined,
        schema: Joi.string(),
      },
    ],
    [
      {
        type: "input",
        typeName: "text",
        size: "grow2",
        name: "admission_5_0_RoomNum",
        label: "Room Num",
        value: undefined,
        schema: Joi.string().max(30),
      },
      {
        type: "input",
        typeName: "text",
        size: "grow1",
        name: "admission_5_1_CaseWorkerName",
        label: "CaseWorker Name",
        value: undefined,
        schema: Joi.string().max(30),
      },
      {
        type: "input",
        typeName: "text",
        size: "grow1",
        name: "admission_5_2_IntakeCoordinatorName",
        label: "IntakeCoordinator Name",
        value: undefined,
        schema: Joi.string().required().max(30),
      },
    ],
  ];
}
