import React from "react";
import { getCurrentUser } from "../../../services/authService";

const FragmentList = ({
  data,
  title,
  onManage,
  list: educationList,
  filter,
  ...props
}) => {
  let getName = (id) => {
    let name = "";
    educationList.forEach((list) => {
      if (list.value == id) name = list.name;
    });
    return name ? name : id;
  };
  let getContact = (id) => {
    let name = "";
    props?.familyContact.forEach((list) => {
      if (list.ID == id)
        name = list.ContactFirstName + " " + list.ContactLastName;
    });
    return name ? name : id;
  };
  console.log(props);

  let user = getCurrentUser();

  if (filter) {
    if (filter !== "all")
      data = data.filter((frag) => frag.AdmissionID === filter);
  }

  return (
    <div className="fragmentList-Container">
      {data.length > 0 ? (
        <div className="fragmentList-List">
          {data.map((entry) => (
            <div className="fragmentList-Item" key={entry.ID}>
              <div className="fragmentList-Item-Title flex justify-between me-4">
                {title == "ContactFirstName" ? (
                  <>
                    <div>{getName(entry.ContactTypeId)}</div>
                    <div>{entry.ContactFirstName}</div>
                    <div>{entry.Phone}</div>
                  </>
                ) : title == "ChildName" ? (
                  <>
                    <div>
                      {entry[title] ?? getContact(entry.PartnerContactID)}
                    </div>

                    {/* <div>{entry.Phone}</div> */}
                  </>
                ) : educationList ? (
                  getName(entry[title])
                ) : entry[title] ? (
                  entry[title]
                ) : (
                  "-"
                )}
              </div>
              <button className="b" onClick={() => onManage(entry)}>
                {user.isCaseCoordinator ? "Manage" : "View"}
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className="fragmentList-NoData">No Entries</div>
      )}
    </div>
  );
};

export default FragmentList;
