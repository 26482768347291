import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  addInfraction,
  deleteInfractions,
  getAllInfractions,
  getInfractions,
} from "../../../services/infractionService";
import { toast } from "react-toastify";
import { level1Access } from "../../../utils/roles";

function checkDate(date) {
  const inputDate = new Date(date);
  const now = new Date();
  const currentDay = now.getDay();
  const lastSunday = new Date(now);
  lastSunday.setDate(now.getDate() - currentDay);
  lastSunday.setHours(0, 0, 0, 0);
  return inputDate >= lastSunday && inputDate <= now;
}

const InfractionsManagement = ({
  ResID,
  AdmissionID,
  listInfractions,
  setListInfractions,
  user,
}) => {
  const [selectedInfraction, setSelectedInfraction] = useState(null);
  const [infractions, setInfractions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const res = await getAllInfractions();
      const inf = await getInfractions(AdmissionID);
      setListInfractions(inf.data);
      setInfractions(res.data);
    }
    fetchData();
  }, []);

  function dateShower(date) {
    if (date) {
      let sDate = new Date(date);
      sDate = sDate.toLocaleString();
      return sDate;
    }
  }

  const handleSelectChange = (selectedOption) => {
    setSelectedInfraction(selectedOption);
  };

  const handleSubmit = async () => {
    if (selectedInfraction) {
      console.log(`Submitting infraction: ${selectedInfraction.value}`);
      let currentInfraction = infractions.find(
        (i) => i.ID === selectedInfraction.value
      );
      const newInfraction = {
        Date: new Date().toISOString(),
        ID: selectedInfraction.value,
        Infraction: currentInfraction.Infraction,
        Dismissal: currentInfraction.Dismissal,
        Points: currentInfraction.Points,
        ResID,
        AdmissionID,
      };
      let res = await addInfraction(newInfraction);
      if (res.status === 200) {
        setListInfractions([newInfraction, ...listInfractions]);
      } else {
        toast.error("Failed to create infraction");
      }

      setSelectedInfraction(null);
    }
  };

  const handleDelete = async (ID, AdmissionID) => {
    const res = await deleteInfractions(ID, AdmissionID);
    const updatedInfractions = listInfractions?.filter((inf) => inf.ID !== ID);
    setListInfractions(updatedInfractions);
  };

  return (
    <div className="mx-auto ">
      <div className="flex items-center justify-between mb-4"></div>
      {level1Access(user) && !user.isFinance ? (
        <div className="flex items-center mb-4">
          <Select
            id="infraction"
            value={selectedInfraction}
            onChange={handleSelectChange}
            options={infractions?.map((infraction) => ({
              value: infraction.ID,
              label: infraction.Infraction,
            }))}
            className="mr-2 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            placeholder="Select an infraction"
          />
          <button
            onClick={handleSubmit}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 disabled:bg-gray-300"
            disabled={!selectedInfraction}
          >
            Submit
          </button>
        </div>
      ) : (
        <></>
      )}
      <div className="max-h-[230px] overflow-y-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date & Time
              </th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Infraction
              </th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Points
              </th>
              {level1Access(user) && !user.isFinance ? (
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              ) : (
                <></>
              )}
            </tr>
          </thead>
          <tbody>
            {listInfractions?.map((infraction, index) => (
              <tr key={index} className="border-t border-gray-200">
                <td className="px-4 py-2 whitespace-nowrap">
                  {dateShower(infraction?.Date)}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  <span
                    className={`inline-flex px-2 text-xs font-semibold leading-5 rounded-full ${
                      checkDate(infraction.Date)
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800"
                    }`}
                  >
                    {checkDate(infraction.Date) ? "Active" : "Inactive"}
                  </span>
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {infraction.Infraction}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  {infraction.Points}
                </td>
                {level1Access(user) && !user.isFinance ? (
                  <td className="px-4 py-2 whitespace-nowrap">
                    <button
                      onClick={() => handleDelete(infraction.ID, AdmissionID)}
                      className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                    >
                      Delete
                    </button>
                  </td>
                ) : (
                  <></>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InfractionsManagement;
