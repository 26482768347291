import Joi from "joi-browser";

export function getFamilyObject() {
  return [
    [
      {
        type: "input",
        typeName: "text",
        size: "grow1",
        name: "family_0_0_ChildName",
        divideRow: true,
        label: "Child Name",
        value: undefined,
        schema: Joi.string().max(30).required(),
      },
      {
        type: "date",
        size: "grow1",
        name: "family_0_1_ChildDob",
        divideRow: true,
        label: "Child DOB",
        value: null,
        schema: Joi.date().allow(null),
      },
    ],
    [
      {
        type: "select",
        typeName: "text",
        size: "grow1",
        name: "family_1_0_PartnerContactID",
        label: "Partner",
        options: [],
        divideRow: true,
        value: undefined,
        schema: Joi.string().max(30),
      },
      {
        type: "input",
        typeName: "text",
        size: "grow1",
        divideRow: true,
        name: "family_1_1_CustodyOfChild",
        label: "Who has custody of child?",
        value: undefined,
        schema: Joi.string().max(30),
      },
    ],
    [
      {
        type: "checkbox",
        size: "grow1",
        name: "family_2_0_IsPregnant",
        label: "Is Pregnant",
        divideRow3: true,
        className: "custom-form-check my-4",
        value: false,
        schema: Joi.boolean(),
      },
      {
        type: "checkbox",
        size: "grow1",
        name: "family_2_1_ChildInHouseFlag",
        label: "Child In House",
        divideRow3: true,
        className: "custom-form-check my-4",
        value: false,
        schema: Joi.boolean(),
      },
      {
        type: "checkbox",
        size: "grow1",
        name: "family_2_2_HasChildSupport",
        label: "Has Child Support",
        value: false,
        divideRow3: true,
        className: "custom-form-check my-4",
        schema: Joi.boolean(),
      },

      // {
      //   type: "input",
      //   typeName: "text",
      //   size: "grow2",
      //   name: "family_2_2_PartnerName",
      //   label: "Co-parent Name",
      //   value: undefined,
      //   schema: Joi.string().max(30),
      // },
    ],

    [
      // {
      //   type: "input",
      //   typeName: "text",
      //   size: "grow3",
      //   name: "family_3_0_PartnerName",
      //   label: "Partner Name",
      //   value: undefined,
      //   schema: Joi.string().max(30),
      // },
      {
        type: "checkbox",
        size: "grow1",
        name: "family_3_0_ReceivesChildSupport",
        label: "Receives Child Support",
        divideRow3: true,
        value: false,
        className: "custom-form-check my-4",
        schema: Joi.boolean(),
      },
      {
        type: "checkbox",
        size: "grow1",
        name: "family_3_1_PaysChildSupport",
        label: "Pays Child Support",
        divideRow3: true,
        className: "custom-form-check my-4",
        value: false,
        schema: Joi.boolean(),
      },

      {
        type: "input",
        typeName: "number",
        size: "grow2",
        name: "family_3_2_ChildSupportAmount",
        label: "Child Support Amount",
        divideRow3: true,
        value: undefined,
        schema: Joi.number(),
      },
    ],
  ];
}
