// // Import necessary dependencies
import React, { useState } from "react";
import { generateBilling } from "../services/billingProfileService";
import { CSVLink } from "react-csv";
// Utility function to get the start and end dates of the selected month
const getMonthStartAndEndDates = (year, month) => {
  // Ensure `month` is within 1-12 range
  if (month < 1 || month > 12) {
    throw new Error("Month must be between 1 and 12.");
  }

  // Start date: first day of the month
  const startDate = new Date(year, month - 1, 1);

  // End date: last day of the month
  const endDate = new Date(year, month, 0);
  console.log(startDate, endDate);
  // Format date as YYYY-MM-DD without converting to UTC
  const formatDate = (date) => {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  return {
    start: formatDate(startDate),
    end: formatDate(endDate),
  };
};

const GenerateBilling = () => {
  // Calculate the default year and month (one month prior to current date)
  const currentDate = new Date();
  const defaultMonth =
    currentDate.getMonth() === 0 ? 12 : currentDate.getMonth(); // December if January (0-based month)
  const defaultYear =
    currentDate.getMonth() === 0
      ? currentDate.getFullYear() - 1
      : currentDate.getFullYear();

  // State to manage the selected year and month
  const [year, setYear] = useState(defaultYear);
  const [month, setMonth] = useState(defaultMonth);
  const [data, setData] = useState({
    "Full Month Stay": [],
    "Started Mid-Month": [],
    "Left Mid-Month": [],
  });
  // Handle submit
  const handleSubmit = async () => {
    setData({
      "Full Month Stay": [],
      "Started Mid-Month": [],
      "Left Mid-Month": [],
    });
    const { start, end } = getMonthStartAndEndDates(year, month);
    try {
      const response = await generateBilling(start, end);
      setData(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="residentView-sectionBox mx-0">
      <div className="w-full flex flex-col justify-between mb-4">
        <div>
          <h2 className="text-3xl font-semibold text-teal-600 mb-6">
            Generate Billing
          </h2>
        </div>
        <div className="mx-auto bg-gray-100 mt-10 w-full flex flex-col items-center rounded-lg  shadow-md">
          <div className="flex flex-col items-center space-y-4 p-4 w-78">
            <h2 className="text-2xl font-semibold text-gray-700">
              Select Month & Year
            </h2>
            <div className="flex flex-row w-full justify-between">
              {/* Year Selector */}
              <label className="text-gray-600">
                Year
                <select
                  value={year}
                  onChange={(e) => setYear(Number(e.target.value))}
                  className="block w-full mt-1 p-2 border rounded-lg bg-white shadow-sm focus:ring-teal-600 focus:border-teal-600"
                >
                  {Array.from(
                    { length: 2 },
                    (_, i) => new Date().getFullYear() - 1 + i
                  ).map((yr) => (
                    <option key={yr} value={yr}>
                      {yr}
                    </option>
                  ))}
                </select>
              </label>

              {/* Month Selector */}
              <label className="text-gray-600">
                Month
                <select
                  value={month}
                  onChange={(e) => setMonth(Number(e.target.value))}
                  className="block w-full mt-1 p-2 border rounded-lg bg-white shadow-sm focus:ring-teal-600 focus:border-teal-600"
                >
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i + 1} value={i + 1}>
                      {new Date(0, i).toLocaleString("default", {
                        month: "long",
                      })}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            {/* Submit Button */}
            <button
              onClick={handleSubmit}
              className="w-full px-4 py-2 font-semibold text-white bg-teal-600 rounded-lg shadow-md hover:bg-teal-600 focus:outline-none"
            >
              Generate
            </button>
          </div>
          <div className="flex flex-row justify-center items-center gap-4 mb-6">
            {data["Full Month Stay"]?.length > 0 ||
            data["Started Mid-Month"]?.length > 0 ||
            data["Left Mid-Month"]?.length > 0 ? (
              <CSVLink
                data={[
                  ...(data["Full Month Stay"] ?? []),
                  ...(data["Started Mid-Month"] ?? []),
                  ...(data["Left Mid-Month"] ?? []),
                ]}
                filename={
                  "All Residents, " +
                  new Date(0, month - 1).toLocaleString("default", {
                    month: "long",
                  }) +
                  ", " +
                  year +
                  ".csv"
                }
                className="w-full no-underline text-center font-semibold text-white bg-teal-600 rounded-lg shadow-md hover:bg-teal-600 focus:outline-none"
              >
                <div className="w-full px-4 py-2 min-w-36 font-semibold text-white bg-teal-600 rounded-lg shadow-md hover:bg-teal-600 focus:outline-none">
                  All Residents
                  <p className="text-xl font-bold pt-3 mb-0">
                    {
                      [
                        ...(data["Full Month Stay"] ?? []),
                        ...(data["Started Mid-Month"] ?? []),
                        ...(data["Left Mid-Month"] ?? []),
                      ]?.length
                    }
                  </p>
                </div>
              </CSVLink>
            ) : (
              <div></div>
            )}
            {data["Full Month Stay"]?.length > 0 && (
              <CSVLink
                data={data["Full Month Stay"] || []}
                filename={
                  "Full Month Stay, " +
                  new Date(0, month - 1).toLocaleString("default", {
                    month: "long",
                  }) +
                  ", " +
                  year +
                  ".csv"
                }
                className="w-full no-underline text-center font-semibold text-white bg-teal-600 rounded-lg shadow-md hover:bg-teal-600 focus:outline-none"
              >
                <div className="w-full px-4 py-2 min-w-[11rem] font-semibold text-white bg-teal-600 rounded-lg shadow-md hover:bg-teal-600 focus:outline-none">
                  Full Month Stay{" "}
                  <p className="text-xl font-bold pt-3 mb-0">
                    {data["Full Month Stay"]?.length}
                  </p>
                </div>
              </CSVLink>
            )}
            {data["Started Mid-Month"]?.length > 0 && (
              <CSVLink
                data={data["Started Mid-Month"] || []}
                filename={
                  "Started Mid-Month, " +
                  new Date(0, month - 1).toLocaleString("default", {
                    month: "long",
                  }) +
                  ", " +
                  year +
                  ".csv"
                }
                className="w-full no-underline text-center font-semibold text-white bg-teal-600 rounded-lg shadow-md hover:bg-teal-600 focus:outline-none"
              >
                <div className="w-full px-4 py-2 min-w-[12.5rem] font-semibold text-white bg-teal-600 rounded-lg shadow-md hover:bg-teal-600 focus:outline-none">
                  Started Mid-Month
                  <p className="text-xl font-bold pt-3 mb-0">
                    {data["Started Mid-Month"]?.length}
                  </p>
                </div>
              </CSVLink>
            )}
            {data["Left Mid-Month"]?.length > 0 && (
              <CSVLink
                data={data["Left Mid-Month"] || []}
                filename={
                  "Left Mid-Month, " +
                  new Date(0, month - 1).toLocaleString("default", {
                    month: "long",
                  }) +
                  ", " +
                  year +
                  ".csv"
                }
                className="w-full no-underline text-center font-semibold text-white bg-teal-600 rounded-lg shadow-md hover:bg-teal-600 focus:outline-none"
              >
                <div className="w-full px-4 py-2 min-w-[11rem] font-semibold text-white bg-teal-600 rounded-lg shadow-md hover:bg-teal-600 focus:outline-none">
                  Left Mid-Month{" "}
                  <p className="text-xl font-bold pt-3 mb-0">
                    {data["Left Mid-Month"]?.length}
                  </p>
                </div>
              </CSVLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateBilling;
