import http from "./httpService";

const apiEndpoint = "/service-agreement";

export async function getAllServiceAgreement(ResID) {
  let url = `${apiEndpoint}/${ResID}`;
  try {
    return await http.get(url);
  } catch (error) {
    return { error };
  }
}

export async function createServiceAgreement(data) {
  let url = `${apiEndpoint}/`;
  try {
    return await http.post(url, data);
  } catch (error) {
    return { error };
  }
}
export async function updateServiceAgreement(data) {
  let url = `${apiEndpoint}/`;
  try {
    return await http.put(url, data);
  } catch (error) {
    return { error };
  }
}
export async function deleteServiceAgreement(id) {
  let url = `${apiEndpoint}/${id}`;
  try {
    return await http.delete(url);
  } catch (error) {
    return { error };
  }
}
