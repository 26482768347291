import React, { useState, useEffect } from "react";
import ToggleList from "../../components/common/residentView_Common_Components/ToggleList";
import {
  addDeviceToAdmission,
  getActiveResidentsWithDevices,
  removeDeviceFromAdmission,
  toggleCheckInResidentDevice,
} from "../../services/residentService";
import { toast } from "react-toastify";

const Modal = ({
  isOpen,
  onClose,
  options,
  selected,
  onChange,
  onSelectAll,
  onDeselectAll,
  onAddResidents,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
        <h3 className="text-xl font-semibold mb-4">Select Residents</h3>
        <div className="flex justify-between mb-4">
          <button
            onClick={onSelectAll}
            className="bg-emerald-600 text-white px-4 py-2 rounded hover:bg-teal-800"
          >
            Select All
          </button>
          <button
            onClick={onDeselectAll}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
          >
            Deselect All
          </button>
        </div>
        <div className="max-h-64 overflow-y-auto mb-4">
          {options.map((option) => (
            <div
              key={option._id}
              className="flex items-center justify-between p-2"
            >
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={selected.includes(option.value)}
                  onChange={() => onChange(option.value)}
                  className="form-checkbox h-4 w-4 text-teal-800"
                />
                <span className="ml-2">{option.name}</span>
              </label>
            </div>
          ))}
        </div>
        <div className="flex justify-end space-x-2">
          <button
            onClick={onAddResidents}
            className="bg-emerald-600 text-white px-4 py-2 rounded hover:bg-teal-700"
          >
            Add Resident(s)
          </button>
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

function CheckIn(props) {
  const [deviceType, setDeviceType] = useState("Mobile");
  const [residents, setResidents] = useState([]);
  const [inProcess, setInProcess] = useState(true);
  const [WithoutDevice, setWithoutDevice] = useState([]);
  const [CheckedInRes, setCheckInRes] = useState([]);
  const [CheckedOutRes, setCheckOutRes] = useState([]);
  const [resToAdd, setResToAdd] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getResidents();
  }, []);

  const getResidents = async () => {
    try {
      const { data: residentsFound } = await getActiveResidentsWithDevices();
      setResidents(residentsFound);
      setInProcess(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (residents.length === 0) {
      setCheckInRes([]);
      setCheckOutRes([]);
      setWithoutDevice([]);
    }
    let tempWithoutDevice = [];
    let tempCheckedInRes = [];
    let tempCheckedOutRes = [];

    residents.forEach((resident) => {
      if (resident["Has" + deviceType]) {
        if (resident["CheckedIn" + deviceType]) tempCheckedInRes.push(resident);
        else tempCheckedOutRes.push(resident);
      } else {
        tempWithoutDevice.push(resident);
      }
    });
    console.log(tempCheckedInRes);
    setWithoutDevice(tempWithoutDevice);
    setCheckInRes(tempCheckedInRes);
    setCheckOutRes(tempCheckedOutRes);
  }, [residents, deviceType]);

  const checkInResident = async (res) => {
    setInProcess(true);
    await toggleCheckInResidentDevice({
      checkIn: true,
      deviceType,
      id: res.AdmissionID,
    });
    getResidents();
    toast.success(
      `Checked In ${res.ResFirstName + " " + res.ResLastName}'s ${deviceType}`
    );
  };

  const checkOutResident = async (res) => {
    setInProcess(true);
    await toggleCheckInResidentDevice({
      checkIn: false,
      deviceType,
      id: res.AdmissionID,
    });
    getResidents();
    toast.success(
      `Returned ${res.ResFirstName + " " + res.ResLastName}'s ${deviceType}`
    );
  };

  const addDeviceToResidentAddmission = async () => {
    if (resToAdd.length > 0) {
      setInProcess(true);
      for (let resId of resToAdd) {
        await addDeviceToAdmission({ deviceType, id: resId });
      }
      getResidents();
      setResToAdd([]);
      toast.success(`${deviceType} added to selected Residents`);
      setIsModalOpen(false); // Close modal after adding residents
    }
  };

  const removeDevice = async (res) => {
    setInProcess(true);
    await removeDeviceFromAdmission({ deviceType, id: res.AdmissionID });
    getResidents();
    toast.success(
      `Removed ${res.ResFirstName + " " + res.ResLastName}'s ${deviceType}`
    );
  };

  const handleSelectChange = (value) => {
    setResToAdd((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((id) => id !== value)
        : [...prevSelected, value]
    );
  };

  const handleSelectAll = () => {
    setResToAdd(WithoutDevice.map((res) => res.AdmissionID));
  };

  const handleDeselectAll = () => {
    setResToAdd([]);
  };

  return (
    <div className="p-6">
      <div className="flex items-center justify-between mb-4">
        <div>
          <h2 className="text-2xl font-semibold">Device Check In :</h2>
          <h2 className="text-xl text-teal-800">{deviceType}</h2>
        </div>
        <div className="space-x-4">
          <button
            className={`px-4 py-2 rounded ${
              deviceType === "Mobile"
                ? "bg-teal-700 text-white"
                : "border border-teal-700 text-teal-700"
            }`}
            disabled={inProcess || deviceType === "Mobile"}
            onClick={() => setDeviceType("Mobile")}
          >
            Mobile
          </button>
          <button
            className={`px-4 py-2 rounded ${
              deviceType === "Tablet"
                ? "bg-teal-700 text-white"
                : "border border-teal-700 text-teal-700"
            }`}
            disabled={inProcess || deviceType === "Tablet"}
            onClick={() => setDeviceType("Tablet")}
          >
            Tablet
          </button>
        </div>
      </div>
      {WithoutDevice.length > 0 && (
        <div className="flex flex-row bg-gray-100 p-4 rounded-lg mb-4">
          <div>
            <h5 className="text-lg mb-2">
              Add active residents with {deviceType}
            </h5>
          </div>
          <div>
            <button
              onClick={() => setIsModalOpen(true)}
              className="bg-teal-700 text-white px-4 py-2 rounded hover:bg-teal-800"
            >
              Add Residents
            </button>
          </div>
        </div>
      )}

      {/* Modal for selecting residents */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        options={WithoutDevice.map((res) => ({
          _id: res.ResID,
          name: `${res.ResFirstName} ${res.ResLastName}`,
          value: res.AdmissionID,
        }))}
        selected={resToAdd}
        onChange={handleSelectChange}
        onSelectAll={handleSelectAll}
        onDeselectAll={handleDeselectAll}
        onAddResidents={addDeviceToResidentAddmission}
      />
      <div className="grid grid-cols-2 gap-4">
        <div className="checkin-Container-Section-Individual">
          <h4 className="primary">On Person</h4>
          <ToggleList
            data={CheckedOutRes}
            onToggle={checkInResident}
            onRemove={removeDevice}
          />
        </div>
        <div className="checkin-Container-Section-Individual">
          <h4 className="primary">Checked In</h4>
          <ToggleList
            data={CheckedInRes}
            onToggle={checkOutResident}
            onRemove={removeDevice}
          />
        </div>
      </div>
    </div>
  );
}

export default CheckIn;
