import React, { useEffect, useState } from "react";
import {
  deleteResidentAdmissionRecord,
  setResidentAdmissionRecord,
} from "../../services/residentService";
import { toast } from "react-toastify";
import { getList } from "../../services/listService";
import { getUsers } from "../../services/users/users_admin";
import { level3Access } from "../../utils/roles";
import { getCurrentUser } from "../../services/authService";
import { FaTrash } from "react-icons/fa";
let tempPD = null;

const AdmissionSummary = (props) => {
  const [admittedFromList, setAdmittedFromList] = useState([]);
  const [admittedFromOther, setAdmittedFromOther] = useState(false);
  const [maritalStatusList, setMaritalStatusList] = useState([]);
  const [reload, setReload] = useState(false);
  const [showDischarge, setShowDischarge] = useState(false);
  const [admissionData, setAdmissionData] = useState({
    AdmissionID: null,
    AdmissionNotesID: null,
    BlockFromReentry: null,
    CanSelfSignout: null,
    CaseWorkerName: null,
    CheckedInMobile: null,
    CheckedInTablet: null,
    DateOut: null,
    DischargeLocation: null,
    DisciplinaryPoints: null,
    EstMoveOutDate: null,
    ExitNotes: null,
    GuestInDate: null,
    HasMentalHealthChallanges: null,
    HasMobile: null,
    HasTablet: null,
    ID: null,
    IntakeCoordinatorName: null,
    IsApprovedBabySitter: null,
    IsApprovedPartner: null,
    IsRestricted: null,
    LastModified: null,
    LeftVolunteerly: null,
    ModifiedBy: null,
    PhaseData: null,
    PossessionsRemovedDate: null,
    PreviousInpatientAttempts: null,
    ProgramInDate: null,
    ReadmitConditions: null,
    ReasonForLeaving: null,
    RecentPhase: null,
    ReferredByContactID: null,
    ResID: null,
    RoomNum: null,
    TimesCompletedTreatment: null,
    TreatmentCenterListID: null,
    AdmittedFrom: null,
    UnresolvedIssues: null,
    WasDomesticallyAbused: null,
    WasHomeless: null,
    WasJobless: null,
    WhoReferred: null,
    WhoReferredRelationship: null,
    ChurchName: null,
    MentorName: null,
    MaritalStatus: null,
    LastCounty: null,
    OnMA: null,
    OnSSI: null,
    HasChildren: null,
  });
  const [users, setUsers] = useState([]);
  const user = getCurrentUser();

  function dateFormatter(d) {
    if (d) return d.slice(0, 10);
  }
  function dateShower(date) {
    if (date) {
      let dateString = date.slice(0, 10);
      dateString = dateString.split("-");
      return dateString[1] + "-" + dateString[2] + "-" + dateString[0];
    }
  }
  // Optionally, you can update the state with props.data if it exists
  React.useEffect(() => {
    async function getData() {
      let AdmittedFromList = await getList(8);
      let MaritalStatusList = await getList(5);
      setAdmittedFromList(AdmittedFromList);
      setMaritalStatusList(MaritalStatusList);
    }
    getData();
    if (props.data) {
      setAdmissionData(props.data);
      if (props.data?.ProgramInDate) {
        tempPD = props.data?.ProgramInDate;
      }
      if (props.data?.DateOut) setShowDischarge(true);
    }
  }, [props.data]);

  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        let { data, error } = await getUsers();
        console.log(data);
        if (error) return setUsers([]);
        data = data.filter(
          (user1) => !user1.isAdmin && !user1.isProductionSupport
        );
        setUsers(data);
      } catch (error) {
        setUsers([]);
      }
    }
    fetchData();
  }, []);

  const handleSave = async () => {
    // Add save functionality here
    console.log(admissionData);
    const res = await setResidentAdmissionRecord(admissionData);
    if (res.status === 200) {
      toast.success(res.data.message);
      setIsEditing(false);
    } else if (res.status === 202) {
      // console.log(tempPD);
      // setAdmissionData((prev) => ({
      //   ...admissionData,
      //   ProgramInDate: tempPD ?? null,
      // }));
      // setReload(true);
      // setTimeout(() => {
      //   setReload(false);
      // }, 1);
      toast.error(res.data.message);
    } else toast.error(res.data.message);
  };

  const handleCancel = () => {
    setIsEditing(false);
    if (props.data) {
      setAdmissionData(props.data);
    }
    // Add cancel functionality here (reset form to initial state)
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name == "AdmittedFrom" && value == "Other") {
      setAdmittedFromOther(true);
      setAdmissionData((prevData) => ({
        ...prevData,
        [name]: "",
      }));
    } else if (name == "BlockFromReentry" || name == "LeftVolunteerly") {
      setAdmissionData((prevData) => ({
        ...prevData,
        [name]: value === "false" ? false : true,
      }));
    } else if (value === "") {
      setAdmissionData((prevData) => ({
        ...prevData,
        [name]: null,
      }));
    } else {
      setAdmissionData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    // setAdmittedFromOther(false)
  };

  const deleteAdmission = async (AdmissionID) => {
    const res = await deleteResidentAdmissionRecord(AdmissionID);
    if (res?.status === 200) {
      toast.success("Admission deleted successfully");
      window.history.back();
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setAdmissionData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const formatDate = (date) => {
    if (date) {
      let dateString = date.slice(0, 10);
      dateString.split("-");
      return dateString[2] + "-" + dateString[1] + "-" + dateString[0];
    }
  };

  return (
    <div className="w-full">
      <div className="p-8 bg-background self-center flex flex-col">
        <div className="bg-white shadow-lg rounded-lg p-6 w-full">
          <div className="grid grid-cols-2 gap-6 pb-4">
            <div className="col-span-2 flex justify-between items-center border-b-2 border-gray-400 border-dashed pb-4 mb-6">
              <div className="text-black font-bold text-[24px] me-6 self-center">
                {localStorage.getItem("resName")}
              </div>
              <div className="flex flex-row max-w-sm">
                <span>
                  Guest In Date:{" "}
                  <div className="relative inline-block">
                    {!isEditing ? (
                      <strong className="text-teal-600 font-bold text-md">
                        {admissionData.GuestInDate
                          ? dateShower(admissionData.GuestInDate)
                          : null}
                      </strong>
                    ) : (
                      !reload && (
                        <div className="flex items-center">
                          <input
                            type="date"
                            name="GuestInDate"
                            value={
                              dateFormatter(admissionData?.GuestInDate) || ""
                            }
                            className="ms-2"
                            onChange={handleInputChange}
                            disabled={!isEditing}
                          />
                          <button
                            type="button"
                            className="ml-2"
                            onClick={() => {
                              setAdmissionData((prev) => ({
                                ...admissionData,
                                GuestInDate: null,
                              }));
                              setReload(true);
                              setTimeout(() => {
                                setReload(false);
                              }, 1);
                            }}
                          >
                            X
                          </button>
                        </div>
                      )
                    )}
                  </div>
                </span>
              </div>
              <div>
                <span>
                  Program In Date:{" "}
                  <div className="relative inline-block">
                    {!isEditing ? (
                      <strong className="text-teal-600 font-bold text-md">
                        {admissionData.ProgramInDate
                          ? dateShower(admissionData.ProgramInDate)
                          : null}
                      </strong>
                    ) : (
                      !reload && (
                        <div className="flex items-center">
                          <input
                            type="date"
                            name="ProgramInDate"
                            value={
                              dateFormatter(admissionData?.ProgramInDate) || ""
                            }
                            className="ms-2"
                            onChange={handleInputChange}
                            disabled={!isEditing}
                          />
                          <button
                            type="button"
                            className="ml-2"
                            onClick={() => {
                              setAdmissionData((prev) => ({
                                ...admissionData,
                                ProgramInDate: null,
                              }));
                              setReload(true);
                              setTimeout(() => {
                                setReload(false);
                              }, 1);
                            }}
                          >
                            X
                          </button>
                        </div>
                      )
                    )}
                  </div>
                </span>
              </div>
              {level3Access(user) && !user.isFinance && (
                <div>
                  {isEditing ? (
                    <>
                      <button
                        className="bg-teal-600 ms-4 text-white px-4 py-2 rounded mr-2"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                      <button
                        className="bg-teal-600 text-white px-4 py-2 rounded"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <div className="flex items-center">
                      <button
                        className="bg-teal-600 ms-8 text-white px-4 py-2 rounded"
                        onClick={handleEdit}
                      >
                        Edit
                      </button>
                      {admissionData?.DateOut && (
                        <button
                          className="px-4 py-2 rounded cursor-none"
                          onClick={() =>
                            deleteAdmission(admissionData.AdmissionID)
                          }
                        >
                          <FaTrash size={23} />
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="grid grid-cols-4 gap-6 pb-4 mb-6">
            <div>
              <label className="block text-secondary">Case Worker Name</label>

              <select
                name="CaseWorkerName"
                value={admissionData.CaseWorkerName || ""}
                className="w-full border rounded px-2 py-1"
                onChange={handleInputChange}
                disabled={!isEditing}
              >
                {users.some(
                  (user) =>
                    user.firstName + " " + user.lastName !=
                    admissionData?.CaseWorkerName
                ) ? (
                  <option value={admissionData?.CaseWorkerName}>
                    {admissionData?.CaseWorkerName}
                  </option>
                ) : (
                  <option value={""}>Select from Dropdown</option>
                )}
                {users.map((user) => (
                  <option value={user.firstName + " " + user.lastName}>
                    {user.firstName} {user.lastName}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-secondary">
                Intake Coordinator Name
              </label>
              <select
                name="IntakeCoordinatorName"
                value={admissionData.IntakeCoordinatorName || ""}
                className="w-full border rounded px-2 py-1"
                onChange={handleInputChange}
                disabled={!isEditing}
                required
              >
                {users.some(
                  (user) =>
                    user.firstName + " " + user.lastName !=
                    admissionData?.CaseWorkerName
                ) ? (
                  <option value={admissionData?.CaseWorkerName}>
                    {admissionData?.CaseWorkerName}
                  </option>
                ) : (
                  <option value={""}>Select from Dropdown</option>
                )}
                {users.map((user) => (
                  <option value={user.firstName + " " + user.lastName}>
                    {user.firstName} {user.lastName}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-secondary">Admitted from</label>
              {admittedFromOther ||
              (admissionData?.AdmittedFrom &&
                !admittedFromList.some(
                  (adm) => adm.name == admissionData?.AdmittedFrom
                )) ? (
                <div className="relative w-full">
                  <input
                    type="text"
                    name="AdmittedFrom"
                    maxLength={15}
                    value={admissionData.AdmittedFrom || ""}
                    className="w-full border rounded px-2 py-1"
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    placeholder="Enter text..."
                  />
                  {isEditing ? (
                    <svg
                      onClick={() => {
                        if (isEditing) {
                          setAdmissionData((prevData) => ({
                            ...prevData,
                            AdmittedFrom: "",
                          }));
                          setAdmittedFromOther(false);
                        }
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      className="absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer"
                    >
                      <path
                        fill="currentColor"
                        d="M18.36 19.78L12 13.41l-6.36 6.37l-1.42-1.42L10.59 12L4.22 5.64l1.42-1.42L12 10.59l6.36-6.36l1.41 1.41L13.41 12l6.36 6.36z"
                      />
                    </svg>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <select
                  className="w-full border rounded px-2 py-1"
                  name="AdmittedFrom"
                  value={admissionData.AdmittedFrom || ""}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                >
                  <option value="" hidden>
                    Select from Drop Down
                  </option>
                  {admittedFromList.map((adm) => (
                    <option value={adm?.name}>{adm?.name}</option>
                  ))}
                </select>
              )}
            </div>
            <div>
              <label className="block text-secondary">Est Move Out Date</label>
              {!reload && (
                <div className="flex items-center">
                  <input
                    type="date"
                    name="EstMoveOutDate"
                    value={
                      admissionData.EstMoveOutDate
                        ? dateFormatter(admissionData.EstMoveOutDate)
                        : ""
                    }
                    className="w-full border rounded px-2 py-1"
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                  {isEditing && (
                    <button
                      type="button"
                      className="ml-2"
                      onClick={() => {
                        setAdmissionData((prev) => ({
                          ...admissionData,
                          EstMoveOutDate: null,
                        }));
                        setReload(true);
                        setTimeout(() => {
                          setReload(false);
                        }, 1);
                      }}
                    >
                      X
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <div>
                <h3 className="text-xl font-bold mb-4 text-teal-600 border-b pb-2">
                  Previous Record
                </h3>
                <div className="grid grid-cols-2 gap-6 mb-6">
                  <div>
                    <label className="block text-secondary">
                      Treatment Completed Previously
                    </label>
                    <input
                      type="text"
                      name="TimesCompletedTreatment"
                      value={admissionData.TimesCompletedTreatment || ""}
                      className="w-full border rounded px-2 py-1"
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    />
                  </div>
                  <div>
                    <label className="block text-secondary">
                      Previous Inpatient Attempts
                    </label>
                    <input
                      type="text"
                      name="PreviousInpatientAttempts"
                      value={admissionData.PreviousInpatientAttempts || ""}
                      className="w-full border rounded px-2 py-1"
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    />
                  </div>
                </div>
              </div>
              <div>
                <h3 className="text-xl font-bold mb-4 text-teal-600 border-b pb-2">
                  Referrals
                </h3>
                <div className="grid grid-cols-2 gap-6 mb-6">
                  <div>
                    <label className="block text-secondary">Who Referred</label>
                    <input
                      type="text"
                      name="WhoReferred"
                      value={admissionData.WhoReferred || ""}
                      className="w-full border rounded px-2 py-1"
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    />
                  </div>
                  <div>
                    <label className="block text-secondary">
                      Who Referred - Relationship
                    </label>
                    <input
                      type="text"
                      name="WhoReferredRelationship"
                      value={admissionData.WhoReferredRelationship || ""}
                      className="w-full border rounded px-2 py-1"
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h3 className="text-xl font-bold mb-4 text-teal-600 border-b pb-2">
                Additional Information
              </h3>
              <div className="grid grid-cols-2 gap-6 mb-6">
                <div>
                  <label className="block text-secondary">Church Name</label>
                  <input
                    type="text"
                    name="ChurchName"
                    value={admissionData.ChurchName || ""}
                    className="w-full border rounded px-2 py-1"
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                </div>
                <div>
                  <label className="block text-secondary">Mentor Name</label>
                  <input
                    type="text"
                    name="MentorName"
                    value={admissionData.MentorName || ""}
                    className="w-full border rounded px-2 py-1"
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                </div>
                <div>
                  <label className="block text-secondary">Last County</label>
                  <input
                    type="text"
                    name="LastCounty"
                    value={admissionData.LastCounty || ""}
                    className="w-full border rounded px-2 py-1"
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                </div>
                <div className="">
                  <label className="block text-secondary">Marital Status</label>
                  <select
                    className="w-full border rounded px-2 py-1"
                    disabled={!isEditing}
                    value={admissionData.MaritalStatus || null}
                    name="MaritalStatus"
                    onChange={handleInputChange}
                  >
                    <option value={null} hidden>
                      Select
                    </option>
                    {maritalStatusList.map((ms) => (
                      <option value={ms?.name}>{ms?.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-4 mt-4">
                <label className="text-teal-600 flex items-center">
                  <input
                    type="checkbox"
                    name="HasChildren"
                    checked={admissionData.HasChildren || false}
                    className="mr-2"
                    onChange={handleCheckboxChange}
                    disabled={!isEditing}
                  />
                  Has Children
                </label>
                <label className="text-teal-600 flex items-center">
                  <input
                    type="checkbox"
                    name="WasHomeless"
                    checked={admissionData.WasHomeless || false}
                    className="mr-2"
                    onChange={handleCheckboxChange}
                    disabled={!isEditing}
                  />
                  Was Homeless
                </label>
                <label className="text-teal-600 flex items-center">
                  <input
                    type="checkbox"
                    name="WasJobless"
                    checked={admissionData.WasJobless || false}
                    className="mr-2"
                    onChange={handleCheckboxChange}
                    disabled={!isEditing}
                  />
                  Employed
                </label>
              </div>
              <p className="mt-4 pt-2 pb-0 mb-0 text-gray-500">Conditions</p>
              <div className="grid grid-cols-3 gap-4 mt-2 pb-0">
                <label className="text-teal-600 flex items-center">
                  <input
                    type="checkbox"
                    name="OnMA"
                    checked={admissionData.OnMA || false}
                    className="mr-2"
                    onChange={handleCheckboxChange}
                    disabled={!isEditing}
                  />
                  On MA
                </label>
                <label className="text-teal-600 flex items-center">
                  <input
                    type="checkbox"
                    name="OnSSI"
                    checked={admissionData.OnSSI || false}
                    className="mr-2"
                    onChange={handleCheckboxChange}
                    disabled={!isEditing}
                  />
                  On SSI/SSD
                </label>
                <label className="text-teal-600 flex items-center">
                  <input
                    type="checkbox"
                    name="HasMentalHealthChallanges"
                    checked={admissionData.HasMentalHealthChallanges || false}
                    className="mr-2"
                    onChange={handleCheckboxChange}
                    disabled={!isEditing}
                  />
                  Mental Health Challenges
                </label>
              </div>
              <p className="mt-4 pb-0 mb-0 text-gray-500">Program</p>
              <div className="grid grid-cols-3 gap-4 mt-2">
                <label className="text-teal-600 flex items-center">
                  <input
                    type="checkbox"
                    name="CanSelfSignout"
                    checked={admissionData.CanSelfSignout || false}
                    className="mr-2"
                    onChange={handleCheckboxChange}
                    disabled={!isEditing}
                  />
                  Can Self sign-out
                </label>
                <label className="text-teal-600 flex items-center">
                  <input
                    type="checkbox"
                    name="IsApprovedPartner"
                    checked={admissionData.IsApprovedPartner || false}
                    className="mr-2"
                    onChange={handleCheckboxChange}
                    disabled={!isEditing}
                  />
                  Approved Partner
                </label>
                <label className="text-teal-600 flex items-center">
                  <input
                    type="checkbox"
                    name="IsRestricted"
                    checked={admissionData.IsRestricted || false}
                    className="mr-2"
                    onChange={handleCheckboxChange}
                    disabled={!isEditing}
                  />
                  Restricted
                </label>
              </div>
            </div>
          </div>
        </div>
        {showDischarge ? (
          <div className="bg-white shadow-lg rounded-lg p-6 mt-10 w-full">
            <div className="pb-4">
              <div className="col-span-2 flex justify-between items-center border-b-2 border-gray-400 border-dashed pb-4 mb-6">
                <div className="text-teal-600 font-bold text-xl me-6">
                  DISCHARGE SUMMARY
                </div>
                <div className="flex flex-row max-w-sm">
                  <span>
                    Date Out:{" "}
                    {!isEditing ? (
                      <strong className="text-teal-600 font-bold text-md">
                        {dateShower(admissionData.DateOut)}
                      </strong>
                    ) : (
                      !reload && (
                        <div className="flex items-center">
                          <input
                            type="date"
                            name="DateOut"
                            value={
                              admissionData.DateOut
                                ? dateFormatter(admissionData.DateOut)
                                : ""
                            }
                            className="w-full border rounded px-2 py-1"
                            onChange={handleInputChange}
                            disabled={!isEditing}
                          />
                          <button
                            type="button"
                            className="ml-2"
                            onClick={() => {
                              setAdmissionData((prev) => ({
                                ...admissionData,
                                DateOut: null,
                              }));
                              setReload(true);
                              setTimeout(() => {
                                setReload(false);
                              }, 1);
                            }}
                          >
                            X
                          </button>
                        </div>
                      )
                    )}
                  </span>
                </div>
                <div>
                  <span>
                    Possessions Removed Date:{" "}
                    {!isEditing ? (
                      <strong className="text-teal-600 font-bold text-md">
                        {dateShower(admissionData.PossessionsRemovedDate)}
                      </strong>
                    ) : (
                      !reload && (
                        <div className="flex items-center">
                          <input
                            type="date"
                            name="PossessionsRemovedDate"
                            value={
                              admissionData.PossessionsRemovedDate
                                ? dateFormatter(
                                    admissionData.PossessionsRemovedDate
                                  )
                                : ""
                            }
                            className="w-full border rounded px-2 py-1"
                            onChange={handleInputChange}
                            disabled={!isEditing}
                          />
                          <button
                            type="button"
                            className="ml-2"
                            onClick={() => {
                              setAdmissionData((prev) => ({
                                ...admissionData,
                                PossessionsRemovedDate: null,
                              }));
                              setReload(true);
                              setTimeout(() => {
                                setReload(false);
                              }, 1);
                            }}
                          >
                            X
                          </button>
                        </div>
                      )
                    )}
                  </span>
                </div>
                <div>
                  {isEditing ? (
                    <>
                      <button
                        className="bg-teal-600 text-white px-4 py-2 rounded mr-2"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                      <button
                        className="bg-teal-600 text-white px-4 py-2 rounded"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      <button className="bg-transparent text-transparent px-4 py-2 rounded mr-2 cursor-none">
                        Cancel
                      </button>
                      <button
                        className="bg-teal-600 text-white px-4 py-2 rounded"
                        onClick={handleEdit}
                      >
                        Edit
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2">
                <label className="block text-secondary">
                  Reason for Leaving
                </label>
                <textarea
                  name="ReasonForLeaving"
                  value={admissionData.ReasonForLeaving || ""}
                  className="w-full border rounded px-2 py-1"
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </div>
              <div className="col-span-2">
                <label className="block text-secondary">
                  Discharge Location
                </label>
                <textarea
                  name="DischargeLocation"
                  value={admissionData.DischargeLocation || ""}
                  className="w-full border rounded px-2 py-1"
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </div>
              <div className="flex items-center">
                <label className="block text-secondary mr-2">
                  Block From Re-entry
                </label>
                <div>
                  <input
                    type="radio"
                    name="BlockFromReentry"
                    value={true}
                    checked={admissionData.BlockFromReentry}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                  <label className="ml-1">Yes</label>
                </div>
                <div className="ml-4">
                  <input
                    type="radio"
                    name="BlockFromReentry"
                    value={false}
                    checked={
                      admissionData.BlockFromReentry !== null &&
                      !admissionData.BlockFromReentry
                    }
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                  <label className="ml-1">No</label>
                </div>
              </div>
              <div className="flex items-center">
                <label className="block text-secondary mr-2">
                  Left Voluntarily
                </label>
                <div>
                  <input
                    type="radio"
                    name="LeftVolunteerly"
                    value={true}
                    checked={admissionData.LeftVolunteerly}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                  <label className="ml-1">Yes</label>
                </div>
                <div className="ml-4">
                  <input
                    type="radio"
                    name="LeftVolunteerly"
                    value={false}
                    checked={
                      admissionData.LeftVolunteerly !== null &&
                      !admissionData.LeftVolunteerly
                    }
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                  <label className="ml-1">No</label>
                </div>
              </div>
              <div className="col-span-2">
                <label className="block text-secondary">
                  Unresolved Issues
                </label>
                <textarea
                  name="UnresolvedIssues"
                  value={admissionData.UnresolvedIssues || ""}
                  className="w-full border rounded px-2 py-1"
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </div>
              <div className="col-span-2">
                <label className="block text-secondary">
                  Readmit Conditions
                </label>
                <textarea
                  name="ReadmitConditions"
                  value={admissionData.ReadmitConditions || ""}
                  className="w-full border rounded px-2 py-1"
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </div>
              <div className="col-span-2">
                <label className="block text-secondary">Other Exit Notes</label>
                <textarea
                  name="ExitNotes"
                  value={admissionData.ExitNotes || ""}
                  className="w-full border rounded px-2 py-1"
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>{" "}
    </div>
  );
};

export default AdmissionSummary;
