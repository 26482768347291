import React from "react";

const HRMLDate = ({
  name,
  label,
  error,
  value,
  onChange,
  className,
  ...rest
}) => {
  return (
    <div className={`date ${label === "Child DOB" ? "pb-4" : ""}`}>
      {label && <p className="date-label">{label}</p>}
      <div className="pb-3 ms-0">
        <div className="">
          <input
            type="date"
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            className={className + " form-control"}
            {...rest}
          />
        </div>
      </div>
      {error && (
        <div className="alert alert-danger">
          {label + " must be a valid date"}
        </div>
      )}
    </div>
  );
};

export default HRMLDate;
