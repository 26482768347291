import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";

class Form extends Component {
  state = {
    data: "",
    errors: "",
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;
    const errors = {};

    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  renderButton(label) {
    return (
      <div className="buttonRow">
      <button disabled={this.validate()} className="button">
        {label}
      </button>
      </div>
    );
  }

  // renderSelect(name, label, options) {
  //     const { data, errors } = this.state;

  //     return (
  //         <Select
  //             name={name}
  //             value={data[name]}
  //             label={label}
  //             options={options}
  //             onChange={this.handleChange}
  //             error={errors[name]}
  //         />
  //     );
  // }

  renderInput(name, label, type = "text") {
    const { data, errors } = this.state;
    return (
      <Input
        type={type}
        onChange={this.handleChange}
        name={name}
        label={label}
        value={data[name]}
        error={errors[name]}
        />
    );
  }
}

export default Form;
