/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  getResidentByID,
  getAdmission,
  updateResident,
  getResidentFragment,
  updateResidentPhase,
  getResidentAdmissionRecords,
  updateResidentImage,
  deleteResident,
} from "../../services/residentService";
import DisciplinePoints from "../../components/common/residentView_Common_Components/DisciplinePoints";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import Form from "../common/simpleForm";
import { getResidentUpdateObject } from "../../utils/residentUpdateObject";
import { getResidentNotes } from "../../services/residentFragments/frag_notes";
import NotesList from "../../components/common/residentView_Common_Components/notesList";
import CreateNote from "../../components/common/residentView_Common_Components/createNote";
import FragmentList from "../../components/common/residentView_Common_Components/fragmentsList";
import CreateFragment from "../../components/common/residentView_Common_Components/createFragment";
import PhaseList from "../../components/common/residentView_Common_Components/phaseList";
import PhaseChange from "../../components/common/residentView_Common_Components/phaseChange";
import UpdateFragment from "../../components/common/residentView_Common_Components/updateFragment";
import { getList } from "../../services/listService";
import { getCurrentUser } from "../../services/authService";
import {
  financeAccess,
  isCenterOrCaseCoordinator,
  level1Access,
  level2Access,
  level3Access,
  level4Access,
  level5Access,
} from "../../utils/roles";
import { toast } from "react-toastify";
import AdmissionRecords from "../../components/common/residentView_Common_Components/AdmissionRecords";
import Select from "../../components/common/select";
import AWSImagePicker from "../../components/common/awsImagePicker";
import awsService from "../../services/awsService";
import AWSImagePickerEdit from "../../components/common/awsImagePickerEdit";
import ResidentInfo from "../../components/common/ProfileHeaderForm";
import ProfileHeaderForm from "../../components/common/ProfileHeaderForm";
import ResTopBar from "../../components/common/ResTopBar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FileUpload from "../../components/common/residentView_Common_Components/fileUpload";
import ServiceAgreementLetters from "./ServiceAgreementLetters";
//===========================[ DisciplinaryPoints ]===========================
// import DisciplinaryClipboard from "../../components/common/residentView_Common_Components/disciplinaryClipboard";
function checkDate(date) {
  const inputDate = new Date(date);
  const now = new Date();
  const currentDay = now.getDay();
  const lastSunday = new Date(now);
  lastSunday.setDate(now.getDate() - currentDay);
  lastSunday.setHours(0, 0, 0, 0);
  return inputDate >= lastSunday && inputDate <= now;
}
const UpdateResident = (props) => {
  const ResID = window.location.pathname.split("/")[3];
  const user = getCurrentUser();
  const [resident, setResident] = useState();
  const [listInfractions, setListInfractions] = useState([]);
  const [admission, setAdmission] = useState();
  //===========================[ DisciplinaryPoints ]===========================
  // const [totalPoints, setTotalPoints] = useState(0);
  const [profileUpdateData, setProfileUpdateData] = useState(
    getResidentUpdateObject()
  );
  const history = useHistory();
  const [ProfileUpdatemessage, setProfileUpdatemessage] = useState("");
  const [Notes, setNotes] = useState();
  const [NotesState, setNotesState] = useState("View");
  const [Fragments, setFragments] = useState([]);
  const [FragmentToBeUpdated, setFragmentToBeUpdated] = useState({});
  const [phaseInfo, setPhaseInfo] = useState();
  const [PhaseState, setPhaseState] = useState("View");
  const [AdmissionHistory, setAdmissionHistory] = useState();
  const [FragmentFilter, setFragmentFilter] = useState("all");
  const [FragmentFilterDropdownOptions, setFragmentFilterDropdownOptions] =
    useState([{ _id: "all", name: "All Fragments", value: "all" }]);
  const [showModal, setShowModal] = useState(false);

  const handleDelete = async () => {
    const res = await deleteResident(ResID);
    console.log(res);
    if (res.data?.message) {
      toast.success(res.data.message);
      setShowModal(false);
      history.push("/dashboard");
    }
  };
  useEffect(() => {
    const getandSetResident = async () => {
      try {
        //get Resident
        let { data: queriedResident } = await getResidentByID(ResID);
        if (queriedResident) {
          console.log(queriedResident);
          setResident(queriedResident);
          localStorage.setItem(
            "resName",
            queriedResident?.ResFirstName + " " + queriedResident?.ResLastName
          );
          //get Admission
          let tempAdmission;
          if (queriedResident.IsActive) {
            let { data: queriedAdmission } = await getAdmission(ResID);
            tempAdmission = queriedAdmission;
            if (queriedAdmission) setAdmission(queriedAdmission);
          }
          //get Notes
          if (level1Access(user)) {
            const notes = await getResidentNotes(ResID);
            if (notes.data?.length > 0) setNotes(notes.data);
            //   if(notes.data?.length >0) setNotes([])
            else setNotes([]);
          }

          //get Admissions
          if (level1Access(user)) {
            const admissions = await getResidentAdmissionRecords(ResID);
            console.log("Admissions", admissions.data);
            if (admissions.data?.length > 0) {
              // admissions.data = admissions.data.filter((adms) =>
              //   adms.DateOut ? true : false
              // );
              if (admissions.data?.length > 0)
                setAdmissionHistory(admissions.data);
            }
          } else {
            if (tempAdmission) {
              //   setFragmentFilterDropdownOptions([{
              //   _id : "current",
              //   name : "Current Admission",
              //   value : tempAdmission.AdmissionID
              // }])
              setFragmentFilter(tempAdmission.AdmissionID);
            }
          }
          if (level1Access(user) || tempAdmission) getFragments();
        } else {
          //
        }
      } catch (error) {
        //
      }
    };
    getandSetResident();
  }, []);

  function dateShower(date) {
    if (date && !(date instanceof Date)) {
      console.log(date);
      let dateString = date?.slice(0, 10);
      dateString = dateString.split("-");
      return dateString[1] + "-" + dateString[2] + "-" + dateString[0];
    }
  }

  useEffect(() => {
    if (!AdmissionHistory) return;
    let admissionData = AdmissionHistory.map((admission) => ({
      _id: admission.AdmissionID,
      out: admission.DateOut,
      in: admission.ProgramInDate
        ? admission.ProgramInDate
        : admission.GuestInDate,
    }));
    admissionData.sort((a, b) => new Date(b.in) - new Date(a.in));
    let dropdownOptions = [
      { _id: "all", name: "All Admissions", value: "all" },
    ];
    if (admission)
      dropdownOptions.push({
        _id: "current",
        name: "Current Admission",
        value: admission.AdmissionID,
      });
    console.log(dropdownOptions);
    admissionData.forEach((add, i) => {
      let string = `Admission ${admissionData.length - i} :  ${dateShower(
        add.in
      )} - ${dateShower(add.out)}`;
      dropdownOptions.push({
        _id: add._id,
        name:
          i === admissionData.length - 1
            ? add.out
              ? string
              : "Current Admission"
            : string,
        value: add._id,
      });
    });
    setFragmentFilterDropdownOptions(dropdownOptions);
  }, [AdmissionHistory]);

  useEffect(async () => {
    //setProfile

    if (resident) {
      let lists = await getList(7);
      const tempProfieData = [...profileUpdateData];
      profileUpdateData.forEach((row, i) => {
        row.forEach((item, i2) => {
          let key = item.name.split("_")[3];
          if (resident[key]) tempProfieData[i][i2].value = resident[key];
        });
      });
      tempProfieData[2][3].options = lists;
      setProfileUpdateData(tempProfieData);
    }
  }, [resident]);

  useEffect(() => {
    if (admission) {
      let phaseData = admission.PhaseData;
      console.log(phaseData);
      if (!phaseData) {
        phaseData = [
          {
            phase: admission.RecentPhase,
            inDate: admission.GuestInDate,
          },
        ];
      } else {
        phaseData = JSON.parse(phaseData);
        phaseData = phaseData.map((item, i) => {
          return {
            id: i,
            inDate: item?.inDate,
            outDate: item?.outDate,
            phase: item?.phase,
          };
        });
      }
      console.log(phaseData);
      setPhaseInfo(phaseData);

      //===========================[ DisciplinaryPoints ]===========================

      // let tempPoints = admission.DisciplinaryPoints;
      // if(tempPoints) tempPoints = JSON.parse(tempPoints)
      // if (tempPoints?.length > 0) {
      //   let tempTotal = 0;
      //   tempPoints.forEach((temp) => {
      //     tempTotal = tempTotal + temp.points;
      //   });
      //   setTotalPoints(tempTotal);
      // } else {
      //   setTotalPoints(0);
      // }
    }
  }, [admission]);

  const getFragments = async () => {
    let educationList = await getList(2);
    let conatctTypeList = await getList(11);
    console.log(conatctTypeList);
    const fragmentsArray = [
      {
        title: "Contacts",
        name: "contacts",
        items: [],
        state: "View",
        titleName: "ContactFirstName",
        list: conatctTypeList,
      },
      {
        title: "Medication",
        name: "medication",
        items: [],
        state: "View",
        titleName: "MedicationName",
      },
      {
        title: "Drug",
        name: "drug",
        items: [],
        state: "View",
        titleName: "DrugOfChoice",
      },
      {
        title: "Education",
        name: "education",
        items: [],
        state: "View",
        titleName: "EducationLevel",
        list: educationList,
      },
      {
        title: "Employment",
        name: "employment",
        items: [],
        state: "View",
        titleName: "JobTitle",
      },
      {
        title: "Medical",
        name: "medical",
        items: [],
        state: "View",
        titleName: "Condition",
      },
      {
        title: "Legal",
        name: "legal",
        items: [],
        state: "View",
        titleName: "CaseName",
      },
      {
        title: "Family",
        name: "family",
        items: [],
        state: "View",
        titleName: "ChildName",
      },
      //   { title: "Family", name: "family", items: [], state : "View", titleName : "" },
      //   { title: "Contacts", name: "contacts", items: [], state : "View", titleName : "" },
      //   { title: "Finance", name: "finance", items: [], state : "View", titleName : "" },
    ];

    for (let i = 0; i < fragmentsArray.length; i++) {
      const fragment = fragmentsArray[i];
      let result = await getResidentFragment(fragment.name, ResID);
      console.log(result);
      if (result.data?.length > 0) fragmentsArray[i].items = result.data;
      if (i === fragmentsArray.length - 1) {
        setFragments(fragmentsArray);
      }
    }
  };

  // Profile updation
  const handleProfileFieldUpdation = (name, item) => {
    setProfileUpdatemessage(undefined);
    let tempProfileData = [...profileUpdateData];
    tempProfileData[parseInt(name[1], 10)][parseInt(name[2], 10)] = item;
    return setProfileUpdateData(tempProfileData);
  };

  const handleProfileUpdateSubmit = async ({ validation, errorData }) => {
    if (validation) {
      const tempResident = { ResID: resident.ResID };
      profileUpdateData.forEach((row) => {
        row.forEach((item) => {
          let key = item.name.split("_")[3];
          tempResident[key] = item.value;
        });
      });
      console.log(tempResident);
      let additionalData = {
        ResFirstName: resident.ResFirstName,
        ResLastName: resident.ResLastName,
      };
      try {
        let { data } = await updateResident({
          ...tempResident,
          ...additionalData,
        });
        if (data) {
          console.log(data);
          setResident({ ...resident, ...data });
          setProfileUpdatemessage("Saved!");
        } else {
          setProfileUpdatemessage("Failed to Save Resident");
        }
      } catch (error) {
        //@ts-ignore
        setProfileUpdatemessage("Failed to Save Resident");
      }
    } else {
      return setProfileUpdateData(errorData);
    }
  };

  const noteCreated = async () => {
    //get Notes
    const notes = await getResidentNotes(ResID);
    if (notes.data?.length > 0) setNotes(notes.data);
    //   if(notes.data?.length >0) setNotes([])
    else setNotes([]);
    setNotesState("View");
  };

  const setFragmentState = (name, state) => {
    let tempFrags = [...Fragments];
    tempFrags.forEach((frag, i) => {
      if (frag.name === name) {
        tempFrags[i].state = state;
      }
    });
    setFragments(tempFrags);
  };

  const fragmentCreated = async (name) => {
    let fragmentsArray = [...Fragments];
    for (let i = 0; i < fragmentsArray.length; i++) {
      const fragment = fragmentsArray[i];
      if (name === fragment.name) {
        let result = await getResidentFragment(fragment.name, ResID);
        fragmentsArray[i].items = result.data;
        setFragments(fragmentsArray);
      }
    }
    setFragmentState(name, "View");
  };

  const setFragmentToUpdated = (name, data) => {
    let fTBU = { ...FragmentToBeUpdated };
    fTBU[name] = data;
    console.log(fTBU, "pp");
    setFragmentToBeUpdated(fTBU);
    setFragmentState(name, "Manage");
  };

  const phaseChanged = async () => {
    // window.location.reload();
    let { data: queriedResident } = await getResidentByID(ResID);
    if (queriedResident) {
      setResident(queriedResident);

      //get Admission
      if (queriedResident.IsActive) {
        let { data: queriedAdmission } = await getAdmission(ResID);
        if (queriedAdmission) setAdmission(queriedAdmission);
      }
      setPhaseState("View");
    }
  };

  const updatePhase = async (date, index) => {
    console.log(1);
    let tempPhaseInfo = [...phaseInfo];
    tempPhaseInfo[index].outDate = date;
    if (tempPhaseInfo[index + 1]) {
      tempPhaseInfo[index + 1].inDate = date;
    }
    const data1 = {
      ResID: ResID,
      phaseData: tempPhaseInfo,
    };

    try {
      console.log(2);
      let { data } = await updateResidentPhase(data1);
      console.log(3);
      phaseChanged();
      toast.success("Phase transition date Saved!");
    } catch (err) {
      console.log(err);
    }
  };

  const deletePhase = async (id) => {
    console.log(id);
    let tempPhaseInfo = [...phaseInfo];
    let filteredPhase = phaseInfo.filter((p) => p.id !== id);
    console.log(filteredPhase);
    const data1 = {
      ResID: ResID,
      phaseData: filteredPhase,
    };
    console.log(data1);
    try {
      console.log(2);
      let { data } = await updateResidentPhase(data1);
      console.log(3);
      phaseChanged();
      toast.success("Phase Successfully Deleted");
    } catch (err) {
      console.log(err);
    }
  };

  const updateResImage = async ({ url, value, error }) => {
    if (error) return toast.error("Failed to update Image");

    try {
      let { data: updatedImageResident } = await updateResidentImage({
        key: value,
        ResID,
      });
      toast.success("Updated Image");
      return setResident(updatedImageResident);
    } catch (error) {
      if (!value) await awsService.deleteObject(value);
      return toast.error("Failed to update Image");
    }
  };

  return (
    <div>
      <ResTopBar
        user={user}
        resident={resident}
        admissions={AdmissionHistory}
        infractions={listInfractions}
      />
      <div className="dashboard-main-Scrollable pt-[150px]">
        <div className="pt-[55px] px-2">
          <div className="flex justify-end pe-3 pb-3">
            {admission ? (
              <a
                href={`/dashboard/exit/${resident?.ResID}`}
                className="px-4 ms-4 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600 no-underline "
              >
                Discharge
              </a>
            ) : (
              resident &&
              !resident.IsActive &&
              level3Access(user) && (
                <div>
                  {/* <Link
                to={`/dashboard/exit-guest/${resident.ResID}`}
                className="nav-item paddingRight01"
              >
                <button className="b blackButton">Exit Guest</button>
              </Link> */}
                  <Link
                    to={`/dashboard/create-admission/${resident.ResID}`}
                    className="nav-item"
                  >
                    <button className="b">Create Admission</button>
                  </Link>
                </div>
              )
            )}
          </div>
          <div className="grid grid-cols-2">
            {resident && (
              <div className="residentView-sectionBox">
                <div>
                  <AWSImagePickerEdit
                    label={"Update Image"}
                    showLabel={false}
                    name={"resImage"}
                    value={resident.ResPictureKey}
                    onChange={updateResImage}
                    url={resident.ResPictureUrl}
                    buttonText={"+ Change picture"}
                  />
                  {/* <div className="ImagePicker-Box">
                {resident.ResPictureKey ?
            <img loading="lazy"  src={resident.ResPictureKey} className="image" width={"200px"} />
         : 
        <div className="user-profile-box-res">
          <i className="fa fa-user fa-2x light-text" aria-hidden="true"></i>
        </div>
        } */}
                </div>
                <Form
                  data={profileUpdateData}
                  onChange={handleProfileFieldUpdation}
                  submit={handleProfileUpdateSubmit}
                  buttonLabel={"Save"}
                  readOnly={!level2Access(user)}
                ></Form>
                {ProfileUpdatemessage && (
                  <div className="updateResident-footer">
                    {ProfileUpdatemessage}
                  </div>
                )}
              </div>
            )}
            <div className="">
              {Notes && (
                <div className="residentView-sectionBox h-[45rem]">
                  <div className="residentView-sectionBox-header">
                    <h4 className="primary">Case Notes</h4>
                    {NotesState === "View" ? (
                      <button
                        className="b"
                        onClick={() => setNotesState("Create")}
                      >
                        Add Note
                      </button>
                    ) : (
                      <button
                        className="b blackButton"
                        onClick={() => setNotesState("View")}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                  {NotesState === "View" ? (
                    <NotesList data={Notes} />
                  ) : (
                    <CreateNote onCreate={noteCreated} ResId={ResID} />
                  )}
                </div>
              )}
              <div className="pt-1"></div>
              {phaseInfo && (
                <div className="residentView-sectionBox h-[19rem]">
                  <div className="residentView-sectionBox-header">
                    <h4 className="primary">Phase Management</h4>
                    {/* {PhaseState === "Change" && (
                      <button
                        className="b blackButton"
                        onClick={() => setPhaseState("View")}
                      >
                        Cancel
                      </button>
                    )} */}
                  </div>
                  {PhaseState === "View" ? (
                    <div className="PhaseManagement-Container">
                      <div className="PhaseManagement-currentPhase">
                        <h5>Current Phase</h5>
                        <p>{`Phase ${
                          phaseInfo[phaseInfo.length - 1].phase ?? 0
                        }`}</p>
                      </div>
                      <div className="PhaseManagement-currentPhase">
                        <h5>Phase In-Date</h5>
                        <p>{`${dateShower(
                          phaseInfo[phaseInfo.length - 1].inDate
                        )}`}</p>
                      </div>
                      {(level4Access(user) || user?.isCaseCoordinator) && (
                        <div className="PhaseManagement-buttons">
                          <button
                            className="b"
                            onClick={() => setPhaseState("Change")}
                          >
                            Change Phase
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <PhaseChange
                      setPhaseState={setPhaseState}
                      onChange={phaseChanged}
                      ResId={ResID}
                      phaseData={phaseInfo}
                    />
                  )}
                </div>
              )}
            </div>
            {AdmissionHistory && (
              <>
                <div className="residentView-sectionBox">
                  <div className="residentView-sectionBox-header">
                    <h4 className="primary">Admission History</h4>
                  </div>
                  <AdmissionRecords data={AdmissionHistory} />
                </div>
                {/* <div className="residentView-sectionBox-Placeholder"/> */}
              </>
            )}
            {phaseInfo && (
              <div className="residentView-sectionBox">
                <div className="residentView-sectionBox-header">
                  <h4 className="primary">Current Admission - Phase History</h4>
                </div>
                <PhaseList
                  data={phaseInfo}
                  modifyPhase={updatePhase}
                  deletePhase={deletePhase}
                />
              </div>
            )}
          </div>
          {resident?.IsActive &&
            resident?.RecentAdmissionID &&
            level1Access(user) && (
              <div className="residentView-sectionBox">
                <div className="residentView-sectionBox-header">
                  <h4 className="primary">Infractions</h4>
                  <h4 className="primary">
                    Total Points:{" "}
                    {listInfractions
                      ?.filter((i) => checkDate(i.Date))
                      .reduce((sum, infraction) => sum + infraction?.Points, 0)}
                  </h4>
                </div>
                <DisciplinePoints
                  ResID={resident?.ResID}
                  user={user}
                  AdmissionID={resident?.RecentAdmissionID}
                  listInfractions={listInfractions}
                  setListInfractions={setListInfractions}
                />
              </div>
            )}
          {resident?.ResID && financeAccess(user) && (
            <div className="residentView-sectionBox ">
              <ServiceAgreementLetters
                ResID={resident?.ResID}
                level4Access={level4Access}
              />
            </div>
          )}
          {resident && (level2Access(user) || admission) && (
            <>
              <div className="residentView-SubTitle px-2">
                <div className="residentView-Header row">
                  <h2 className="primary ps-1">Resident Fragments</h2>
                  {level2Access(user) && (
                    <div className="checkin-Container-Section backgroundLight marginBottom roundedCorners paddingRight01 paddingLeft01 paddingVertical  alignCenter">
                      <h5>Filter Fragments by Admission</h5>
                      <div className="checkin-Contianer-Dropdown">
                        <Select
                          // options={WithoutDevice.map((res) =>({_id : res.ResID, name : `${res.ResFirstName} ${res.ResLastName}`, value : res.AdmissionID}))}
                          options={FragmentFilterDropdownOptions}
                          onChange={(value) =>
                            setFragmentFilter(value.currentTarget.value)
                          }
                          value={FragmentFilter}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2">
                {Fragments.length > 0 ? (
                  Fragments.map((fragment) => (
                    <div className="residentView-sectionBox">
                      <div className="residentView-sectionBox-header">
                        <h4 className="primary">{fragment.title}</h4>
                        {console.log(
                          user.isCaseCoordinator,
                          user.isAdmin,
                          user.isCenterCoordinator,
                          fragment.state
                        )}
                        {fragment.state === "View" ? (
                          user.isCaseCoordinator ||
                          user.isAdmin ||
                          user.isCenterCoordinator ? (
                            // resident?.IsActive && (
                            <button
                              className="b secondayButton"
                              onClick={() =>
                                setFragmentState(fragment.name, "Create")
                              }
                            >
                              Add
                            </button>
                          ) : (
                            // )
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                      {fragment.state === "View" ? (
                        <FragmentList
                          data={fragment.items}
                          filter={FragmentFilter}
                          title={fragment.titleName}
                          familyContact={Fragments[0].items}
                          list={fragment.list}
                          setFragmentState={setFragmentState}
                          onManage={(data) =>
                            setFragmentToUpdated(fragment.name, data)
                          }
                        />
                      ) : fragment.state === "Manage" ? (
                        <UpdateFragment
                          onUpdate={fragmentCreated}
                          name={fragment.name}
                          setFragmentState={setFragmentState}
                          contactFragment={Fragments[0]}
                          data={FragmentToBeUpdated[fragment.name]}
                        />
                      ) : (
                        <CreateFragment
                          onCreate={fragmentCreated}
                          ResId={ResID}
                          name={fragment.name}
                          setFragmentState={setFragmentState}
                          contactFragment={Fragments[0]}
                          AdmissionID={
                            admission ? admission.AdmissionID : undefined
                          }
                        />
                      )}
                    </div>
                  ))
                ) : (
                  <div className="residentView-sectionBox">Loading...</div>
                )}
              </div>
            </>
          )}

          {resident && level5Access(user) && (
            <>
              {" "}
              <div className="flex items-center justify-end mt-2 pe-3 mb-3">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
                  onClick={() => setShowModal(true)}
                >
                  Delete Resident
                </button>

                {showModal && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded shadow-lg p-6 w-96">
                      <h3 className="text-lg font-semibold">Are you sure...</h3>
                      <p className="mt-2 text-gray-600">
                        Do you really want to delete this Resident?
                      </p>
                      <div className="mt-4 flex justify-end">
                        <button
                          className="px-4 py-2 mr-2 text-white bg-green-500 rounded hover:bg-green-600"
                          onClick={handleDelete}
                        >
                          Yes
                        </button>
                        <button
                          className="px-4 py-2 text-white bg-gray-500 rounded hover:bg-gray-600"
                          onClick={() => setShowModal(false)}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateResident;
