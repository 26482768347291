import http from "./httpService";

const apiEndpoint = "/infractions";

export async function getAllInfractions() {
  let url = `${apiEndpoint}/`;
  try {
    return await http.get(url);
  } catch (error) {
    return { error };
  }
}

export async function getInfractions(AdmissionID) {
  let url = `${apiEndpoint}/get-infractions`;
  try {
    return await http.post(url, { AdmissionID });
  } catch (error) {
    return { error };
  }
}

export async function createInfractions(data) {
  let url = `${apiEndpoint}/`;
  try {
    return await http.post(url, data);
  } catch (error) {
    return { error };
  }
}
export async function updateInfraction(data) {
  let url = `${apiEndpoint}/`;
  try {
    return await http.put(url, data);
  } catch (error) {
    return { error };
  }
}
export async function deleteInfraction(id) {
  let url = `${apiEndpoint}/${id}`;
  try {
    return await http.delete(url);
  } catch (error) {
    return { error };
  }
}

export async function deleteInfractions(ID, AdmissionID) {
  let url = `${apiEndpoint}/delete-infraction`;
  try {
    return await http.post(url, { ID, AdmissionID });
  } catch (error) {
    return { error };
  }
}

export async function addInfraction(data) {
  let url = `${apiEndpoint}/infraction`;
  try {
    return await http.post(url, data);
  } catch (error) {
    return { error };
  }
}
