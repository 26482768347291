import React from "react";

const Select1 = ({
  name,
  label,
  options,
  error,
  noClear,
  className,
  ...rest
}) => {
  return (
    <div className="form-group">
      {label && (
        <label htmlFor={name} className={"inputLabel"}>
          {label}
        </label>
      )}
      <select
        name={name}
        id={name}
        {...rest}
        className={className + " form-control"}
      >
        {!noClear && <option value="" key="0" />}
        {options.map((option) => (
          <option key={option.ID ?? Math.random()} value={option.ID}>
            {option.ContactFirstName} {option.ContactLastName}
          </option>
        ))}
      </select>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Select1;
