import React, { useState } from "react";
import axios from "axios";

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [folderName, setFolderName] = useState("");
  const [message, setMessage] = useState("");

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFolderChange = (e) => {
    setFolderName(e.target.value);
  };

  const handleUpload = async () => {
    if (!file || !folderName) {
      setMessage("Please select a file and enter a folder name");
      return;
    }

    // Check file size (20 MB limit)
    if (file.size > 20 * 1024 * 1024) {
      setMessage("File size exceeds the 20 MB limit");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("folderName", folderName); // Send folder name to the backend

    try {
      const response = await axios.post(
        "http://localhost:3700/api/resident/files/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setMessage("File uploaded successfully!");
    } catch (error) {
      setMessage("Error uploading file");
    }
  };

  return (
    <div>
      <h1>File Upload</h1>
      <input
        type="text"
        placeholder="Enter folder name"
        value={folderName}
        onChange={handleFolderChange}
      />
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default FileUpload;
